.ProjectCont {
  position: relative;
  flex: 1;
  height: auto;

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.ProjectCont .ModalCont:hover ~ div {
  transform: scale(1.03);
}

.ProjectCont > div:last-of-type {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-size: auto 100%;
  transition: transform 0.1s ease-out;
}

.ProjectCont p {
  margin: 0;
}

.ModalCont {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  background-position: center;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1;
}

.ModalCont:hover {
  opacity: 1;
  cursor: pointer;
}

.ModalText {
  position: relative;
  /* top: 100px; */
  opacity: 0;
  transition: opacity 0.5s;
  width: 100%;
}

.ModalCont:hover .ModalText {
  opacity: 1;
}

.ModalCont hr {
  margin: 8px auto;
  width: 0px;
  transition: width 0.8s;
}

.ModalCont:hover hr {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .ProjectCont {
    width: 50%;
    flex: none;
  }
  .ProjectCont > div:last-of-type {
    background-size: cover;
  }
}
