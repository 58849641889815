.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.no-scroll {
  overflow: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ////SLIDER */

.control-dots {
  padding: 0 !important;
  margin-bottom: 0px !important;
}

.dot {
  background: black !important;
  width: 12px !important;
  height: 12px !important;
}
.dot.selected:focus,
.dot:active {
  outline: none;
}

.control.next::before {
  border: none;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px 0 5px !important;
  border: solid white !important;
  border-width: 0 3px 3px 0 !important;
  display: inline-block !important;
  padding: 6px 6px;
}

.control-next::before {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.control-prev::before {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
