.Contact {
  background: url("../../assets/images/Contact/JQcontact.jpg") center / cover
    no-repeat;
  height: 100vh;
  color: white;
}

.ContactCont {
  height: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Footer {
  /* position: sticky; */
  height: 5%;
  /* bottom: 0; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 0.8rem;
}

.Footer p {
  height: 100%;

  padding: 10px 3px;
  background-color: black;

  /* -webkit-box-shadow: 0px -4px 9px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -4px 9px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -4px 9px -3px rgba(0, 0, 0, 0.75); */
}

.DataContainer {
  height: 100%;
  width: 70%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  /* padding: 30px; */
}

.DataContainer a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.Title {
  font-size: 3rem;
  background-color: black;
  width: 40%;
  padding-top: 15px;
}

.ContactData {
  padding: 5px 0 80px 40px;
  /* background-color: rgba(0, 0, 0, 0.7); */
  display: flex;
  flex-direction: column;
}

.ContactData p,
.ContactData a {
  padding: 3px 6px;
  background: transparent;

  transition: all 0.2s ease-out;
}

.ContactData p:hover,
.ContactData a:hover {
  transform: translateX(3px);
  background-position: 0;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 2px;
}
.ContactData p:hover {
  cursor: default;
}

.ManifestCont {
  width: 30%;
  text-align: right;
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ManifestCont h1 {
  background-color: black;
  width: 100%;
  transform: translateY(32px);
  /* transform: translateX(); */
  /* position: relative; */
  /* margin-right: 10px; */
  padding-bottom: 40px;
}

.Manifest {
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: black;
  text-align: left;
  /* background-color: rgba(255, 255, 255, 0.9); */
  padding: 40px 15px 15px 0px;
  font-weight: 300;
}
.Manifest p {
  margin-bottom: 7px;
}

.Manifest a {
  margin-top: 20px;
  color: black;
  text-decoration: none;
  border: 1px solid black;
  padding: 10px 20px;
  transition: all 0.2s ease-out;
  font-weight: 400;
}

.Manifest a:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: 1px solid transparent;
}

@media only screen and (max-width: 600px) {
  .ContactCont {
    font-size: 0.6rem;
  }
  .Title {
    font-size: 1.2rem;
  }
  .Manifest {
    font-size: 0.7rem;
    line-height: 0.9rem;
  }
  .ManifestCont {
    width: 60%;
  }
  .Footer {
    font-size: 0.6rem;
  }
  .ContactData {
    padding: 5px 0 80px 20px;
  }
}
