.LandingCont {
  background: url("../../assets/images/landing/PORTADA.jpg");
  height: 100vh;
  background-size: cover;
  background-position: center -300px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  /* color: white; */

  text-align: left;
}

.Text {
  width: auto;
  margin-left: 80px;
  text-align: left;
  background-color: black;
  color: white;
  padding: 30px;
  /* border-radius: 3px; */
}

.LandingCont h1 {
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 0.9em;
  /* background-color: black; */
}

.LandingCont hr {
  width: 100%;
  /* animation: open 1s; */
  margin: 7px 0;
  color: white;
}

.LandingCont h3 {
  font-weight: 400;
  font-size: 0.9rem;
  /* text-align: right; */
  /* font-style: italic; */
  /* margin-top: 10px; */
}

.Manifiesto {
  width: 40%;
}

@keyframes open {
  from {
    width: 0%;
  }

  to {
    width: 10%;
  }
}

@media only screen and (max-width: 600px) {
  .LandingCont {
    background-size: auto 140%;
    background-position: center;
    align-items: center;
  }
  .Text {
    margin: auto;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1030px) {
  .LandingCont {
    background-size: 130%;
  }
}
