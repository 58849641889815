* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@700&family=Roboto:ital,wght@0,300;0,400;1,300;1,400&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
}
h1,
h2 {
  line-height: 0.8em;
}

h3 {
  line-height: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
