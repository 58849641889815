.ProjectsCont {
  height: 100vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  /* color: white; */
  filter: grayscale(100%);
}

.Title h2 {
  font-size: 3.7rem;
  animation: showText 1s linear;
  /* opacity: 1; */
  position: relative;
  left: 0;
}

.Title hr {
  width: 50%;
  margin: 5px 0 10px 0;
}

.Title {
  text-align: left;
  padding: 0 1.4rem;
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-right: 12px solid black;
}

.White {
  color: black;
  background-color: white;
  border-right: 12px solid black;
}

.Black {
  color: white;
  background-color: black;
  border-right: 12px solid white;
}

.Title p:last-of-type {
  font-style: italic;
}

.Projects {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */

  justify-content: center;
  /* padding: 0 3rem; */
  height: 100%;
  width: 100%;
  /* border-left: 12px solid black; */
}

/* @keyframes showText {
  0% {
    left: -100px;
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
} */

@media only screen and (max-width: 600px) {
  .ProjectsCont {
    flex-direction: column;
  }
  .Title {
    width: 100%;
  }
  .Projects {
    flex-wrap: wrap;
  }
}
