@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@700&family=Roboto:ital,wght@0,300;0,400;1,300;1,400&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
}
h1,
h2 {
  line-height: 0.8em;
}

h3 {
  line-height: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.no-scroll {
  overflow: hidden;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ////SLIDER */

.control-dots {
  padding: 0 !important;
  margin-bottom: 0px !important;
}

.dot {
  background: black !important;
  width: 12px !important;
  height: 12px !important;
}
.dot.selected:focus,
.dot:active {
  outline: none;
}

.control.next::before {
  border: none;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px 0 5px !important;
  border: solid white !important;
  border-width: 0 3px 3px 0 !important;
  display: inline-block !important;
  padding: 6px 6px;
}

.control-next::before {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.control-prev::before {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.Landing_LandingCont__2ygUI {
  background: url(/static/media/PORTADA.54329a83.jpg);
  height: 100vh;
  background-size: cover;
  background-position: center -300px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  /* color: white; */

  text-align: left;
}

.Landing_Text__1ehgH {
  width: auto;
  margin-left: 80px;
  text-align: left;
  background-color: black;
  color: white;
  padding: 30px;
  /* border-radius: 3px; */
}

.Landing_LandingCont__2ygUI h1 {
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 0.9em;
  /* background-color: black; */
}

.Landing_LandingCont__2ygUI hr {
  width: 100%;
  /* animation: open 1s; */
  margin: 7px 0;
  color: white;
}

.Landing_LandingCont__2ygUI h3 {
  font-weight: 400;
  font-size: 0.9rem;
  /* text-align: right; */
  /* font-style: italic; */
  /* margin-top: 10px; */
}

.Landing_Manifiesto__W1BHv {
  width: 40%;
}

@-webkit-keyframes Landing_open__1LOwd {
  from {
    width: 0%;
  }

  to {
    width: 10%;
  }
}

@keyframes Landing_open__1LOwd {
  from {
    width: 0%;
  }

  to {
    width: 10%;
  }
}

@media only screen and (max-width: 600px) {
  .Landing_LandingCont__2ygUI {
    background-size: auto 140%;
    background-position: center;
    align-items: center;
  }
  .Landing_Text__1ehgH {
    margin: auto;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1030px) {
  .Landing_LandingCont__2ygUI {
    background-size: 130%;
  }
}

.Projects_ProjectsCont__2aX_x {
  height: 100vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  /* color: white; */
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.Projects_Title__3m1xh h2 {
  font-size: 3.7rem;
  -webkit-animation: Projects_showText__1sFlt 1s linear;
          animation: Projects_showText__1sFlt 1s linear;
  /* opacity: 1; */
  position: relative;
  left: 0;
}

.Projects_Title__3m1xh hr {
  width: 50%;
  margin: 5px 0 10px 0;
}

.Projects_Title__3m1xh {
  text-align: left;
  padding: 0 1.4rem;
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-right: 12px solid black;
}

.Projects_White__2ygr0 {
  color: black;
  background-color: white;
  border-right: 12px solid black;
}

.Projects_Black__3vWcg {
  color: white;
  background-color: black;
  border-right: 12px solid white;
}

.Projects_Title__3m1xh p:last-of-type {
  font-style: italic;
}

.Projects_Projects__1ECks {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */

  justify-content: center;
  /* padding: 0 3rem; */
  height: 100%;
  width: 100%;
  /* border-left: 12px solid black; */
}

/* @keyframes showText {
  0% {
    left: -100px;
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
} */

@media only screen and (max-width: 600px) {
  .Projects_ProjectsCont__2aX_x {
    flex-direction: column;
  }
  .Projects_Title__3m1xh {
    width: 100%;
  }
  .Projects_Projects__1ECks {
    flex-wrap: wrap;
  }
}

.Project_ProjectCont__3k2nn {
  position: relative;
  flex: 1 1;
  height: auto;

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.Project_ProjectCont__3k2nn .Project_ModalCont__29d5r:hover ~ div {
  transform: scale(1.03);
}

.Project_ProjectCont__3k2nn > div:last-of-type {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-size: auto 100%;
  transition: transform 0.1s ease-out;
}

.Project_ProjectCont__3k2nn p {
  margin: 0;
}

.Project_ModalCont__29d5r {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  background-position: center;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1;
}

.Project_ModalCont__29d5r:hover {
  opacity: 1;
  cursor: pointer;
}

.Project_ModalText__2Ogmk {
  position: relative;
  /* top: 100px; */
  opacity: 0;
  transition: opacity 0.5s;
  width: 100%;
}

.Project_ModalCont__29d5r:hover .Project_ModalText__2Ogmk {
  opacity: 1;
}

.Project_ModalCont__29d5r hr {
  margin: 8px auto;
  width: 0px;
  transition: width 0.8s;
}

.Project_ModalCont__29d5r:hover hr {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .Project_ProjectCont__3k2nn {
    width: 50%;
    flex: none;
  }
  .Project_ProjectCont__3k2nn > div:last-of-type {
    background-size: cover;
  }
}

.Slider_Slider__3JEH1 {
  position: fixed;
  z-index: 1001;
  height: 100vh;
  /* height: 700px !important; */
  /* width: 100%; */
  /* margin: auto; */
  /* background-color: rgba(0, 0, 0, 0.8); */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.7s ease-out;
  right: 0;
  transform: translateX(200vw);
}

.Slider_SliderMove__DbM-h {
  transform: translateX(0);
  /* transition: all 0.7s ease-out; */
  /* right: 0; */
}

.Slider_imgSlider__3W1IJ {
  /* background-repeat: "no-repeat";
  background-position: "center"; */
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
}

.Slider_Cont__14E8m {
  margin-left: auto;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
  background-color: white;
  z-index: 1;
  overflow: scroll;

  /* transition: all 1s ease-out; */
}

.Slider_Exitbtn__2klHr {
  position: absolute;
  top: 0;
  left: 0;
  /* margin: 2px; */
  font-size: 1.3rem;
  /* border: 1px solid black; */
  color: white;
  background-color: black;
  padding: 3px 10px;
  font-family: "heebo";
  /* font-weight: 300; */
  visibility: hidden;
}

.Slider_SliderCont__d2mb6 {
  width: 62%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  /* padding-top: 75px; */
}

.Slider_Description__3REat {
  width: 38%;
  height: 100%;
  padding: 20px;
  text-align: center;
  color: black;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  /* padding: 0 15px; */
  /* transition: transform 2.5s ease-out;
  transition: opacity 7s ease-out; */
}

.Slider_imgContainer__54Tog {
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 5px solid black; */
}

.Slider_DescriptionAlt__3KKqz {
  font-weight: 300;
  font-style: italic;
}

.Slider_textContainer__Ri-Ix {
  height: 15%;
  text-align: right;
  /* transition-delay: 1s; */
  /* transition: opacity 1s cubic-bezier(0.2, 1.06, 0.97, 1.01); */
}

.Slider_textContainer__Ri-Ix h1 {
  color: white;
  padding-top: 15px;
  font-size: 2.5rem;
  font-weight: bold;
  /* background-color: black; */
}

.Slider_Tags__fZ-MF {
  font-size: 0.9rem;
  text-align: right;
  margin: 15px 0 0 auto;
  width: 70%;
  height: 30%;
}

@media only screen and (max-width: 1030px) {
  .Slider_Slider__3JEH1 {
    width: 100%;

    margin: auto;
    display: block;
  }
  .Slider_Cont__14E8m {
    /* height: auto; */
    height: 100vh;
    flex-direction: column;
  }
  .Slider_SliderCont__d2mb6 {
    order: 2;
    width: 90%;
    height: 60%;
  }
  .Slider_Tags__fZ-MF {
    font-size: 0.6rem;
  }
  .Slider_imgContainer__54Tog {
    height: 150px;
    width: 70%;
    margin: 40px auto;
    font-size: 0.5rem;
    display: none;
  }
  .Slider_imgSlider__3W1IJ {
    height: 300px;
  }
  .Slider_Description__3REat {
    order: 1;
    width: 90%;
    flex-direction: column;
    height: 40%;
  }
  .Slider_textContainer__Ri-Ix {
    margin: auto;
    width: 100%;
  }

  .Slider_textContainer__Ri-Ix h1 {
    font-size: 2rem;
  }

  .Slider_textContainer__Ri-Ix h3 {
    font-size: 1rem;
  }

  .Slider_DescriptionAlt__3KKqz {
    font-size: 0.3rem;
  }
  .Slider_Exitbtn__2klHr {
    visibility: visible;
  }
}

.Backdrop_Backdrop__1rIOE {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  height: 100vh;
  width: 100vw;
  transition: all 0.7s ease-out;
  z-index: 1001;
}

.Contact_Contact__2CqGJ {
  background: url(/static/media/JQcontact.9c2aa1ea.jpg) center / cover
    no-repeat;
  height: 100vh;
  color: white;
}

.Contact_ContactCont__13YDJ {
  height: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Contact_Footer__QOOWN {
  /* position: sticky; */
  height: 5%;
  /* bottom: 0; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 0.8rem;
}

.Contact_Footer__QOOWN p {
  height: 100%;

  padding: 10px 3px;
  background-color: black;

  /* -webkit-box-shadow: 0px -4px 9px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -4px 9px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -4px 9px -3px rgba(0, 0, 0, 0.75); */
}

.Contact_DataContainer__16boo {
  height: 100%;
  width: 70%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  /* padding: 30px; */
}

.Contact_DataContainer__16boo a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.Contact_Title__3rWa5 {
  font-size: 3rem;
  background-color: black;
  width: 40%;
  padding-top: 15px;
}

.Contact_ContactData__oLlX9 {
  padding: 5px 0 80px 40px;
  /* background-color: rgba(0, 0, 0, 0.7); */
  display: flex;
  flex-direction: column;
}

.Contact_ContactData__oLlX9 p,
.Contact_ContactData__oLlX9 a {
  padding: 3px 6px;
  background: transparent;

  transition: all 0.2s ease-out;
}

.Contact_ContactData__oLlX9 p:hover,
.Contact_ContactData__oLlX9 a:hover {
  transform: translateX(3px);
  background-position: 0;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 2px;
}
.Contact_ContactData__oLlX9 p:hover {
  cursor: default;
}

.Contact_ManifestCont__1UhAF {
  width: 30%;
  text-align: right;
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Contact_ManifestCont__1UhAF h1 {
  background-color: black;
  width: 100%;
  transform: translateY(32px);
  /* transform: translateX(); */
  /* position: relative; */
  /* margin-right: 10px; */
  padding-bottom: 40px;
}

.Contact_Manifest__x9N6l {
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: black;
  text-align: left;
  /* background-color: rgba(255, 255, 255, 0.9); */
  padding: 40px 15px 15px 0px;
  font-weight: 300;
}
.Contact_Manifest__x9N6l p {
  margin-bottom: 7px;
}

.Contact_Manifest__x9N6l a {
  margin-top: 20px;
  color: black;
  text-decoration: none;
  border: 1px solid black;
  padding: 10px 20px;
  transition: all 0.2s ease-out;
  font-weight: 400;
}

.Contact_Manifest__x9N6l a:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: 1px solid transparent;
}

@media only screen and (max-width: 600px) {
  .Contact_ContactCont__13YDJ {
    font-size: 0.6rem;
  }
  .Contact_Title__3rWa5 {
    font-size: 1.2rem;
  }
  .Contact_Manifest__x9N6l {
    font-size: 0.7rem;
    line-height: 0.9rem;
  }
  .Contact_ManifestCont__1UhAF {
    width: 60%;
  }
  .Contact_Footer__QOOWN {
    font-size: 0.6rem;
  }
  .Contact_ContactData__oLlX9 {
    padding: 5px 0 80px 20px;
  }
}

.Navigator_NavWrapper__1KJxr {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background: white; */
  padding: 0 15px;
  z-index: 1000;
}

.Navigator_NavWrapper__1KJxr a {
  text-decoration: none;
  transition: color 0.3s ease-out;
}

.Navigator_AnchorCont__mn2t_ {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: black; */
}

.Navigator_White__37W1- {
  color: white;
  background-color: black;
}

.Navigator_Black__QeCsJ {
  color: black;
}

.Navigator_AnchorCont__mn2t_ a {
  padding: 15px;
  height: 100%;
  transition: background-color 0.4s ease-out;
  text-decoration: none;
  /* margin: 0 1px; */
  background-position: -80%;
}

.Navigator_AnchorCont__mn2t_ a:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

